.footer {
  background-color: black;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  color: white;
}
.footer-main {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.footer-left {
  margin-top: 1rem;
}

.footer-right {
  margin-top: 1rem;
}

.footer-middle {
  margin-top: 1rem;
  text-align: center;
}
.footer-middle .footer-content {
  text-align: center;
  margin-top: 2rem;
}
ul {
  list-style: none;
  padding: 0;
  font-size: 1rem;
}
ul li {
  line-height: 2;
}
.footer-content {
}

.footer-heading {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
  color: orange;
}

.footer-copyright {
  text-align: center;
}
.footer-left .footer-heading,
.footer-right .footer-heading {
  text-align: left;
}

.footer-right .footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.footer-main .footer-content {
  font-size: 1rem;
}

.footer-icon {
  height: 5rem;
}

@media only screen and (max-width: 500px) {
  .footer-main .footer-heading {
    font-size: 1rem;
  }
  .footer-middle {
    display: none;
  }
  .footer-main .footer-content {
    font-size: 0.9rem;
  }
}
