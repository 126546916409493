body {
  font-family: "Poppins", sans-serif;
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
  text-transform: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  text-transform: none;
  color: inherit;
}
#root {
  height: auto;
}
.carousel-caption {
  /* right: 1%;
  left: 60%; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0;
}

@media only screen and (max-width: 600px) {
}
